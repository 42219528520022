var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"e7ca5edb753790b357d1c14593bc943cd94657ff"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// // This file configures the initialization of Sentry on the server.
// // The config you add here will be used whenever the server handles a request.
// // https://docs.sentry.io/platforms/javascript/guides/nextjs/

// import * as Sentry from "@sentry/nextjs"
// import { env } from "~/env"

// import { SENTRY_DSN } from "~/lib/sentry/constants"
// import { isVercelProduction } from "~/lib/vercel/constants"

// Sentry.init({
//   dsn: SENTRY_DSN,
//   integrations: [new Sentry.Replay()],
//   tracesSampleRate: 0.25,
//   environment: env.NEXT_PUBLIC_VERCEL_ENV,
//   enabled: isVercelProduction,
// })
